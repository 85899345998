import MetaInfo from "../utils/metainfo"
import ProjectLayout from "../utils/layout"
import Divider from '@mui/material/Divider';
import Persona from "../../images/mygrocery/Persona.png"
import Analysis from "../../images/mygrocery/Analysis.png"
import MyGrocery_background from "../../images/mygrocery/MyGrocery_background.png"
import DesignIdeas from "../../images/mygrocery/DesignIdeas.png"
import DesignArtifacts from "../../images/mygrocery/DesignArtifacts.png"
import Lofi from "../../images/mygrocery/Lofi.png"
import HiFi from "../../images/mygrocery/HiFi.png"
import Product from "../../images/mygrocery/Product.png"
import InDepth from "../../images/mygrocery/InDepth.png"
import Finding from "../../images/mygrocery/Finding.png"

export default function NATS() {
    const metainfo = {
        role: "UX Researcher, Designer & Engineer",
        team: "Myself",
        duration_location: "8 months, Atlanta",
        type: "Master's thesis",
        skills: ["Literature review", "Personas", "Empathy maps", "Journey Maps", "Interviews", "Surveys", "Qualitative Coding",
            "Sketches", "Prototyping", "Heuristic evaluation", "Cognitive walkthrough"],
        tools: ["Qualtrics", "MaxQDA", "Figma/Figjam", "Notion", "Github", "Laravel", "ReactJS", "PHP", "Virtual Conferencing Tool (MS Teams)"],
        projectTitle: "NATS"
    }

    const toc = [
        {
            text: "Problem Space",
            anchor: "problem_space"
        },
        {
            text: "Research Process",
            anchor: "research_process"
        },
        {
            text: "Design & Development Process",
            anchor: "design_process"
        },
        {
            text: "Field Study",
            anchor: "field_study"
        },
        {
            text: "Conclusion",
            anchor: "conclusion"
        }
    ]

    const background = "Prior research indicates that Canadians waste 2.2 million tonnes ($17 billion) of food annually, half of which can be avoided. \
    Food waste not only wastes the food items but also the resources that go into growing and distributing them. In this project, I designed a mobile application that improved the food waste management of people, as part of my capstone project."

    const body =
        <div style={{ "display": "flex", "flexDirection": "column", "maxWidth": "80vw" }}>
            <h2 id="problem_space" className="project-section-title">Problem Space</h2>
            <div className="section-body">
                With food waste leading to a large monetary loss as well as a loss in resources, which could be put to better use, I wanted to design a solution that would help an individual become more aware of their food management practices
                and help them reduce their food wastage. Hence, I sought to address the following questions and goals:
                <ul style={{ "marginTop": "0.25rem" }}>
                    <li>What factors and behaviors contribute to domestic food waste?</li>
                    <li>How can we change the food wastage behaviors of individuals?</li>
                    <li>How can we measure the change in behavior of an individual in this context?</li>
                </ul>

                The following problem statement emerged for this project:
                <div style={{ "marginTop": "0.25rem", "fontWeight": "600", "paddingLeft": "1.5rem" }}>How might I create a mobile application that increases an individual's awareness about their food waste management and helps them improve it?</div>
            </div>
            <Divider />

            <h2 id="research_process" className="project-section-title">Research Process</h2>
            <div className="section-body">
                To gain a better understanding of this space and past efforts done to improve food waste management, I conducted a literature review.
                I then conducted semi-structured interviews to understand the problem from the users perspecitve and to identify key issues.

                <h3 className="project-subsection-title">Target population</h3>
                I interviewed 5 people who were recruited through acquaintances and snowball sampling. 3 participants were male, and 2 female, and were between the ages of 20-40 years.
                <img src={Persona} className="project-writeup-image-50" alt="Persona & empathy map of target user" style={{"marginTop": "2rem" }} />

                <Divider style={{ "marginTop": "2rem" }}>
                    ||
                </Divider>

                <h3 className="project-subsection-title">Analysis & Findings</h3>
                The literature review made me aware of the various solutions available to target this issue, including smart fridges, and that management of the item purchased
                isn't the sole area that leads to food waste, but planning to buy items, shopping, storing, cooking, eating, managing leftovers and assessing edibility, all contribute to food waste.<br />
                I also learnt about behavioral models that can be used to improve a person's waste management practices through this review.<br /><br />

                The data collected from the interviews helped me create the persona and empathy map.<br />
                To analyze the data collected, I used an affinity map to extract themes and patterns related to the food practices of the participants.
                <img src={Analysis} className="project-writeup-image-50"  alt="Affinity map of findings" style={{"marginBottom": "2rem" }} />

                The following reasons for food wastage emerged from the analysis:
                <ul style={{ "marginTop": "0.25rem" }}>
                    <li>People waste food as they buy or order too much</li>
                    <li>People waste food because they don't like it</li>
                    <li>Poor visibility of food leads to wastage</li>
                    <li>People waste food because they forget about it</li>
                    <li>Disruptions to schedule (like a sudden work trip) lead to food wastage</li>
                </ul>

                The interview data along with the literature review helped answer the key questions:

                <div style={{
                    "display": "grid",
                    "gridTemplateColumns": "5fr .5fr 5fr",
                    "marginTop": "2rem"
                }}>
                    <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Key Questions</div>
                    <div></div>
                    <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Findings</div>

                    <div style={{ "marginBottom": "1rem" }}>What factors and behaviors contribute to food waste?</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}>Ordering too much, disruptions to schedule, forgetting about food and poor visibility</div>

                    <div style={{ "marginBottom": "1rem" }}>How can we change food wastage behaviors?</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}>Use of behavioral reinformcements and the Fogg Behavior Model can be used to change behaviors</div>
                </div>

                While analyzing existing solutions, I realized that they tend to target a specific problem area such as storing or shopping, but there wasn't a solution that targeted areas across the cycle.<br />
                So, I decided to focus on creating a product that would help users manage their food waste across all phases of food waste management.
            </div>
            <Divider />
            <h2 id="design_process" className="project-section-title">Design & Development Process</h2>
            <div className="section-body">
            <h3 className="project-subsection-title">Product Features</h3>
            <img src={DesignIdeas} className="project-writeup-image-50" alt="Chart mapping reasons for food wastage to features" style={{"marginBottom": "2rem" }} />
                The findings let me to decide on the following features:
                <div style={{
                    "display": "grid",
                    "gridTemplateColumns": "5fr .5fr 5fr",
                    "marginTop": "2rem"
                }}>
                    <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Findings</div>
                    <div></div>
                    <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Features</div>

                    <div style={{ "marginBottom": "1rem" }}>Users buy too much food that leads to wastage</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Grocery List:</span> To list items for the next grocery run. Checking an item off the list adds it to the inventory.</div>

                    <div style={{ "marginBottom": "1rem" }}>Poor visibility of food leads to wastage</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Inventory:</span> Track all current grocery items with date it is most likely to expire on.</div>

                    <div style={{ "marginBottom": "1rem" }}>Disruptions to schedule lead to food wastage</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Reflections:</span> Allows users to input reasons for food wastage.<br />
                    <span style={{ "fontWeight": "600" }}>Strategies:</span> Allows users to come up with strategies to avoid wastage.
                    </div>

                    <div style={{ "marginBottom": "1rem" }}>Users waste food as they forget about it</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Notifications:</span> To remind users of items that will soon go bad.</div>
                </div>

                <Divider style={{ "marginTop": "2rem" }}>
                    ||
                </Divider>

                <h3 className="project-subsection-title">Interface Design</h3>
                I used a Crazy8 activity to draft rough sketches of the interface for each feature. 
                On finalizing what the interface should look like, I created a storyboard and user flow for each feature to demonstrate what it would look like.
                <img className="project-writeup-image-40" src={DesignArtifacts} alt="A page with 4 sketches of an interface, a comic strip sketch (storyboard) and of a page with arrows between sketched interface screens (user flow)" style={{"margin": "2rem 0rem" }} />
                
                <h4 className="project-section-h4">Low-fidelity prototyping</h4>
                Using the interface decided on, I created low fidelity prototypes of it in the form of paper prototypes. To evaluate it, I created a sequence of tasks for participants to complete, following which I asked them questions to gauge 
                if the interface was easy to navigate.
                <img src={Lofi} className="project-writeup-image-40" alt="Sketches of interface on pieces of paper"/>

                On analyzing the data collected through the low-fidelity evaluation, I discovered that participants would like a way to directly add items to the inventory, than solely relying on the grocery list feature to add it in.
                Hence, the inventory feature needed to be tweaked to incorporate this.

                <Divider style={{ "marginTop": "2rem" }}>
                    ||
                </Divider>

                <h4 className="project-section-h4">High-fidelity prototyping</h4>
                I incorporated the ability to add items directly to the inventory in the high-fidelity prototype. I also added a couple of additional features — the ability to search in the inventory, and being redirected to the reflections 
                page when a user marks an item as wasted in the inventory — to improve the cohesiveness of the product's features. The high fidelity prototype was created on Figma, where a style guide was also established to ensure consistency across 
                screens of the prototype.

                <img src={HiFi} className="project-writeup-image-50" alt="Mobile screens of MyGrocery app"/>

                To evaluate the high fidelity prototype, I had 4 experts perform a heuristic evaluation, and 4 target users perform a cognitive walkthrough of it. 
                The following are the 4 heuristics I asked expert evaluators to evaluate the prototype on, and the 4 tasks I asked users to go through:

                <div style={{
                    "display": "grid",
                    "gridTemplateColumns": "5fr 2fr 5fr",
                    "marginTop": "2rem"
                }}>
                    <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>Heuristics</div>
                    <div></div>
                    <div style={{ "fontWeight": "600", "borderBottom": ".25rem black solid", "marginBottom": ".25rem" }}>User Tasks</div>

                    <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Match between system and the real world:</span> Does the interface use terms familiar to the user</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}>Add an item to the grocery list</div>

                    <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Recognition rather than recall:</span> Is information required to use the design easily available</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}>Add a strategy for an item you wasted before</div>

                    <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Aesthetic and minimalist design:</span> Does the interface only contain relevant information</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}>Add a reflection for a wasted food item</div>

                    <div style={{ "marginBottom": "1rem" }}><span style={{ "fontWeight": "600" }}>Error prevention:</span> Does the design prevent problems for taking place</div>
                    <div></div>
                    <div style={{ "marginBottom": "1rem" }}>Move an item from the grocery list to the inventory</div>
                </div>

                The findings that emerged from the high-fidelity prototype evaluations were:
                <ul style={{ "marginTop": "0.25rem" }}>
                    <li>Participants suggested moving the hamburger menu to a bottom navigation</li>
                    <li>Participants weren't familiar with some of the icons used in the application. <br/>
                    This also appeared in the feedback from the heuristic evaluations as it affected recognition than recall, and was a poor match between system and the real world.</li>
                </ul>

                <Divider style={{ "marginTop": "2rem" }}>
                    ||
                </Divider>

                <h3 className="project-subsection-title">Developing the product</h3>
                I built the product using React Native and used Expo to ensure the product could be used cross-platform. I used Firestore as the database for the application.
                In addition to this, Github was used as the code repository and their kanban board was used for project management. 
                <img className="project-writeup-image-30" src={Product} alt="Mobile screen of MyGrocery application"/>
                I setup biweekly meeting to touch base with the rest of the team to troubleshoot any issues that had come up and to ensure the project was going according to plan.
                Prior to releasing, I performed manual testing on the application and had 23 test cases for this purpose.     
            </div>
            <Divider />
            <h2 id="field_study" className="project-section-title">Field Study</h2>
            <div className="section-body">
                Once the application was released, I conducted a field study to see if it could create an improvement in a user's behavior. There were 2 main areas I wanted to evaluate:
                <ul style={{ "marginTop": "0.25rem" }}>
                    <li>The user experience of the application</li>
                    <li>If the application helped decrease domestic food wastage among users</li>
                </ul>

                To address this, I used 2 modes in the study, a lightweight mode where users evaluated the interface, and an in-depth mode where I monitored decrease in food waste among participants.

                <h3 className="project-subsection-title">Lightweight mode</h3>
                I had 7 users in the lightweight mode. In this mode, participants were asked to interact with the application, following which they were asked to take a survey created on Google Forms 
                where they rated their expereince with the application.

                <Divider style={{ "marginTop": "2rem" }}>
                    ||
                </Divider>

                <h3 className="project-subsection-title">In-depth mode</h3>
                In this mode, the study was conducted over 6 weeks, following which I conducted post-study interviews to understand what the participant's continued use of the application was like.
                In this mode, I had 17 participants — 13 female and 4 male.
                <img src={InDepth} className="project-writeup-image-50" alt="Icons of seeds, sprout and tree with corresponding text of baseline, monitoring and understanding describing the in-depth study"/>
                I first had participants fill out an initial survey to establish a baseline of their current food related practices, following which they were asked to use the application for 6 weeks, where 
                each week they filled out a survey on the amount of food they wasted and their levels of awareness about it.<br/> 
                At the end of 6 weeks, each participant met with me for a post-study interview to share their experience during the study.

                <Divider style={{ "marginTop": "2rem" }}>
                    ||
                </Divider>

                <h3 className="project-subsection-title">Analysis</h3>
                For the lightweight mode, I did a qualitative analysis based on the open-text responses in the survey, and backed it with quantitative metrics from the survey.<br/><br/>
                For the in-depth mode, to account for the various factors involved in an individual's food management process, I used the initial survey as the baseline and the 
                remaining surveys to track the change. I thus evaluated the progress of each participant against their own baseline in the analysis.

                <Divider style={{ "marginTop": "2rem" }}>
                    ||
                </Divider>

                <h3 className="project-subsection-title">Findings</h3>
                From the in-depth mode, I found that 71% of participants reported an overall decrease in their food wastage and 29% reported no change.<br/>
                Moreover, 76% of participants reported an overall increase in levels of awareness around their food wastage. A little over half of the participants reported an overall increase 
                in their ability to more accurately predict when food was going bad.
                <img className="project-writeup-image-40" src={Finding} alt="Text of factors participants rated the application on and corresponding ratings depicted by number of colored apples"/>
                From the lightweight mode, the application received a 4/5 rating. Participants found the application intuitive (4/5 rating), liked the interface (5/5 rating) and were likely to 
                recommend it to others (80% of participants said so).
            </div>
            <Divider />
            <h2 id="conclusion" className="project-section-title">Conclusion</h2>
            I am extremely glad to have had the opportunity to work on this project for my capstone, and am proud with what I accomplished through it (having earned an honorable mention during the Capstone Awards for my year).<br/> 
            Along the way there were many lessons that I learnt, 
            especially since I was conducting a field study virtually. Through this project, I had to wear many hats, which came with it's own challenges, but the greatest lesson I learnt through this experience was to ensure that participants 
            feel supported and to avoid burdening them with too much work (or to fairly compensate them for the work they do).<br/> 
        </div>

    return (
        <>
            <img src={MyGrocery_background} alt="A mobile screen of the MyGrocery application and text describing it is: MyGrocery Capstone Project"
                style={{ "borderRadius": "0rem 4rem 4rem 0rem", "width": "100%", "height": "auto" }} />
            <MetaInfo metainfo={metainfo} />
            <ProjectLayout tableOfContents={toc} prefix="/mygrocery" background={background} body={body} nextProject={{ name: "Cardlytics: UXR", url: "cardlytics_uxr" }} />
        </>
    )
}