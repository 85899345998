import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '../src/home/App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from './layout/Root'
import Project from './layout/Project';
import About from './about/About'
import ErrorPage from './utils/ErrorPage';
import Cardlytics from './project/Cardlytics/Cardlytics';
import MaxMap from './project/MaxMap/MaxMap';
import MyGrocery from './project/MyGrocery/MyGrocery'
import NATS from './project/NATS/NATS'
import './layout/index.css'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root content={<App/>}/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <Root content={<About/>}/>,
  },
  {
    path: "/cardlytics_uxr",
    element: <Project content={<Cardlytics/>}/>,
  },
  {
    path: "/maxmap",
    element: <Project content={<MaxMap/>}/>,
  },
  {
    path: "/mygrocery",
    element: <Project content={<MyGrocery/>}/>,
  },
  {
    path: "/nats",
    element: <Project content={<NATS/>}/>,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
