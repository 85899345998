import Beverly from '../images/Beverly.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button';
import {faChevronDown, faWandMagicSparkles} from '@fortawesome/free-solid-svg-icons'
import './App_height.css';
import './App_pc.css';
import './App_mobile.css';
import ReactFullpage from '@fullpage/react-fullpage'
import '../utils/utils.css';
import { projects } from './projects';
import {useNavigate} from "react-router-dom"

const sectionColors = [
  "FCEFEF",
  "FCEFEF",
  "FCEFEF"
]

const SEL = "custom-section";
const SECTION_SEL = `.${SEL}`;

const onLeave = (origin, destination, direction) => {
  // console.log("onLeave", { origin, destination, direction });
    // arguments are mapped in order of fullpage.js callback arguments do something
    // with the event
}

export default function App() {
  const navigate = useNavigate()
  return (
      <ReactFullpage
        navigation
        licenseKey='FM89I-27QJ9-IJJU6-P0JU6-JZYWM'
        anchors={["intro", "work"]}
        menu='#basic-menu'
        onLeave={onLeave.bind(this)}
        sectionSelector={SECTION_SEL}
        sectionsColor={sectionColors}
        render={
          (comp) => (
            <ReactFullpage.Wrapper>
              <div key='intro' className={SEL}>
                <div className='slide slide-content'>
                  <div className='first-page'>
                    <div className='about'>
                      <img src={Beverly} alt="A woman (Beverly) in glasses wearing a black and gray plaid top" className='profile-image'/>
                      <div className='about-content'>
                        <div className='my-name'><h1>Beverly Vaz</h1></div>
                        <div className='my-roles'>Strategist + Designer + Engineer</div>
                          <div className='my-qualities'>
                            Passionate about crafting&nbsp;
                            <div className='qualities-list-container'>
                              <ul className='qualities-list'>
                                <li className='quality'>accessible user experiences</li>
                                <li className='quality'>inclusive user experiences</li>
                                <li className='quality'>intuitive user experiences</li>
                              </ul>
                            </div>
                          </div>
                        <div className="last-about-line">
                          Currently, crafting experiences for brand success at BAM Strategy&nbsp;
                          <FontAwesomeIcon icon={faWandMagicSparkles} />
                        </div>
                      </div>
                    </div>
                    <Button  className="my-work" aria-label="navigate down" size='small' startIcon={<FontAwesomeIcon icon={faChevronDown} beatFade/>}
                      style={{"display": "flex", "flexDirection": "column", "color": "black", "width": "100%", "marginTop": "0.5rem"}} onClick={() => {window.fullpage_api.moveTo("work")}}
                    >
                      MY WORK
                    </Button>
                  </div>
                </div>
              </div>

              <div key='work' className={SEL}>
                <div className='slide'>
                  <h1 className='section-title'>My Work</h1>
                  <div className='work-container'>
                    {
                      projects.map((project, index) => {
                        return (
                          <div style={{"cursor": "pointer"}} className="work-information" key={`project_${index}`} onClick={()=>navigate(project.link ?? "/")}>
                            <div className='project-image'>
                              <img src={project.src} alt={project.altText} style={{"width": "100%", "height": "100%"}}/>
                            </div>
                            <div className="project-key-info">
                              <div className='project-title'>{project.title}</div>
                              <div className='project-information' style={{"fontWeight": "800"}}>{project.role}</div>
                              <div className='project-information project-description'>{project.description}</div>
                            </div>
                          </div>
                        )
                      })
                    }
                </div>
                </div>
              </div>
            </ReactFullpage.Wrapper>
          )
        }
      />
  );
}