import Beverly_About from '../images/Beverly_About.jpg'
import '../utils/utils.css'
import './about.css'

export default function About() {
    return (
        <div style={{
            "width": "100%",
            "marginTop": "1.5rem",
            "marginBottom": "3rem",
            "display": "flex",
            "flexDirection": "column",
        }}
        className="about-page"
        >
            <h1>About Me</h1>
            <div className='about-image-description'>
                <img src={Beverly_About} alt="A woman (Beverly) in a red checkered top standing by a tree" style={{"maxHeight":"500rem", "borderRadius": "4rem"}} />
                <div style={{"display": "flex", "justifyContent": "center", "alignItems": "center"}} className="about-description">
                    I am a UX generalist, currently working as a CX Strategist at BAM Strategy in Toronto.<br/>
                    Throughout my UX career, I have had the privilege of wearing multiple hats in the UX process, having worked as a researcher, designer, developer and strategist.<br/><br/>
                    Through these experiences, I've gained a deep understanding of the entire product creation journey, from gathering business requirements to design iterations and the development of inclusive platforms.<br/>
                    In my current role, I work with multiple CPG brands to design experiences that meet the users and brands goals.
                </div>
            </div>

            <h2>My Work Philosophy</h2>
            <ul>
                <li>Always place the user's best interests at the heart of the decisions I make</li>
                <li>Always ask "how can I add more value to the work being done by bringing in my diverse background?"</li>
                <li>Always push the boundaries to make my work more inclusive and accessible</li>
            </ul>


            <h2>My Journey</h2>
            Driven by an inherent curiosity, from an early age, questioning has been my way of exploring. A simple one-word answer from my parents would only be met with more questions, till they presented a logical explanation for their answers.
            I was drawn to the field of Computer Science because the logical nature of programming and algorithms appealed to me. It made sense why things were written a certain way and there (usually) was an explanation.
            I successfully completed my undergraduate degree in Software Engineering from the University of Waterloo in Canada, where I interned at various software companies as part of my degree.
            <br />
            <br />If I had to sum up my undergraduate experience with a single word, it would be enriching. I learnt a lot, not just in terms of the wide scope of the field of software engineering, but also about what I was interested in and where I wanted to be.
            Midway through my degree, I realized that though the logical aspect of the field was appealing, I was not satisfied with the role I was preparing to be in. The idea of getting requirements for a product from my manager, and
            coding a feature to meet it, without understanding where these requirements were coming from or seeing the impact it made on the end user, left me dissatisfied. The opacity of the process wasn't something I was happy with, and I wanted to transition
            to something that enabled me to interact with people using the product.
            <br />
            <br />Fortunately, I was soon able to find the right term to describe this abstract role I was seeking — "user-centered". This search propelled me to pursue a Master's degree in Human-Computer Interaction at Georgia Tech.
            It was during this time, that I started getting involved in the field of accessibility, which helped me understand how important it is to take this facet into consideration when designing products, so that a larger section of society can benefit from technological advancements.
            Since then, I have graduated from my Master's degree, and am putting this knowledge to use by ensuring brand experiences are inclusive!

            <h2>My other interests</h2>
            In my leisure time, I try different cuisines. I either try cooking at home (plus points if it's a sweet dish that needs to be baked) or try new eateries wherever I am. I am always open to new food suggestions and conversations around food!<br />
            I love visiting new places and soaking in the change in scenery and local culture that travel brings.<br /><br/>
            In addition to this, I recently started dabbling with embroidery, which I find soothing as I tend to be laser-sharp focused with what I'm doing and tune out from the world around me.<br /><br/>
            I am also gradually trying to incorporate yoga into my daily routine, largely because it allows me to push myself to new limits, and helps me become more aware of the notion that practice makes progress, than perfection.
            It's been interesting to see my gradual progress, and these small achievements give me great joy.

        </div>
    )
}